import React, { Component, createContext } from 'react';
import { isWindowReady } from '@utils';

export const ThemeContext = createContext();

class ThemeContextProvider extends Component {
  constructor() {
    super();

    if (!isWindowReady) {
      return;
    }

    const isDarkTheme = localStorage.getItem('theme') === 'dark';
    const isThemeStored = 'theme' in localStorage;
    const isDarkPrefered = window.matchMedia('(prefers-color-scheme: dark)').matches;

    document.documentElement.classList.remove('dark');
    localStorage.setItem('theme', 'light');
    this.state = { theme: 'light' };

    // TODO: set default theme depending on os settings
    // if (isDarkTheme || (!isThemeStored && isDarkPrefered)) {
    //   document.documentElement.classList.add('dark');
    //   localStorage.setItem('theme', 'dark');
    //   this.state = { theme: 'dark' };
    // } else {
    //   document.documentElement.classList.remove('dark');
    //   localStorage.setItem('theme', 'light');
    //   this.state = { theme: 'light' };
    // }
  }

  toggle = (checked) => {
    const nextTheme = checked ? 'dark' : 'light';

    if (checked) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    localStorage.setItem('theme', nextTheme);
    this.setState({ theme: nextTheme });
  };

  render() {
    return (
      <ThemeContext.Provider value={{ ...this.state, toggle: this.toggle }}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContextProvider;
