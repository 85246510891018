import React from 'react';
import { animated, useSpring } from 'react-spring';

/**
 * @param {number} percent percent value
 */

const ScrollTracker = (props) => {
  const style = useSpring({
    width: `${props.percent}%`,
  });

  return (
    <div className="component-scroll-tracker fixed t-0 w-full h-1 z-50">
      <animated.div className="h-full bg-secondary" style={style} />
    </div>
  );
};

export default ScrollTracker;
