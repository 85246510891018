import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Logo from '@static/svgs/logo/logo-with-text-grayscale.svg';

import { ThemeContext } from '@store';
import { NavLink, ToggleButton } from '@components';

/**
 * @param {string} position absolute or fixed
 */

const Header = (props) => {
  const { theme, toggle } = useContext(ThemeContext);
  const checked = theme === 'dark' ? true : false;

  return (
    <header className={`flex items-center justify-between p-4 w-full z-40 ${props.position}`}>
      <div className="w-40 h-10">
        <Link to="https://stackbase.io">
          <img src={Logo} alt='Stackbase logo' />
          {/* <Logo/> */}
        </Link>
      </div>
      <div className="inline-flex items-center gap-4">
        <div className="inline-flex items-center gap-4">
        <NavLink className="text-dark-gray" url="https://docs.stackbase.io/">
          <span className="hidden md:block">Documentation</span>
        </NavLink>
          <NavLink className="text-dark-gray" url="https://stackbase.io/agency">
          <span className="hidden md:block">Agency</span>
        </NavLink>
         <NavLink className="text-dark-gray" url="https://stackbase.io/blog">
          <span className="hidden md:block">Blog</span>
        </NavLink>
        <NavLink type="button" className=" hidden md:block px-4 py-2 rounded-full text-light-gray bg-primary bg-opacity-25" url="https://portal.stackbase.io/authentication/register">
          <span className="block">Get started for free</span>
        </NavLink>
        <NavLink type="button" className="hidden md:block px-4 py-2 rounded-full text-primary border border-primary" url="https://portal.stackbase.io/">
          <span className="block">Login</span>
        </NavLink>
      </div>
      </div>
    </header>
  );
};

export default Header;
