import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Framework = ({ image, description, title }) => {
  return (
    <div className="max-w-sm rounded-sm p-8 bg-white hover:bg-slate-700 cursor-pointer">
      <div className="flex flex-col items-center  lg:items-start  gap-4 relative">
        <div className="inline-block rounded-full">
          <div className="flex w-12 h-12">
            {/* {image} */}
            {title === 'Javascript' &&  <StaticImage className="rounded" src="../../static/images/brands/logo-javascript.png" alt="API Payload"></StaticImage>}
            {title === 'Angular' &&  <StaticImage className="rounded" src="../../static/images/brands/angular-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Gatsby' &&  <StaticImage className="rounded" src="../../static/images/brands/gatsby-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Vue' &&  <StaticImage className="rounded" src="../../static/images/brands/vue-logo.png" alt="API Payload"></StaticImage>}
            {title === 'React' &&  <StaticImage className="rounded" src="../../static/images/brands/react-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Html' &&  <StaticImage className="rounded" src="../../static/images/brands/html-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Flutter' &&  <StaticImage className="rounded" src="../../static/images/brands/flutter-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Raspberry Pi' &&  <StaticImage className="rounded" src="../../static/images/brands/raspberry-pi-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Arduino' &&  <StaticImage className="rounded" src="../../static/images/brands/arduino-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Bravo Studio' &&  <StaticImage className="rounded" src="../../static/images/brands/bravo-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Cordova' &&  <StaticImage className="rounded" src="../../static/images/brands/cordova-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Ionic' &&  <StaticImage className="rounded" src="../../static/images/brands/ionic-logo.png" alt="API Payload"></StaticImage>}
            {title === 'Create an API in 3 steps using MongoDb' &&  <StaticImage className="rounded" src="../../static/images/mongodb.png" alt="API Payload"></StaticImage>}
            {title === 'Create an API in 3 steps using Google Sheets' &&  <StaticImage className="rounded" src="../../static/images/google-sheets.png" alt="Google sheets"></StaticImage>}
            {title === 'SQL Server' &&  <StaticImage className="rounded" src="../../static/images/brands/mssql.png" alt="Google sheets"></StaticImage>}
            {title === 'MySql' &&  <StaticImage className="rounded" src="../../static/images/brands/mysql.png" alt="Google sheets"></StaticImage>}
            {title === 'AWS S3' &&  <StaticImage className="rounded" src="../../static/images/brands/s3.png" alt="Google sheets"></StaticImage>}
          </div>
        </div>
        <div>
          <h4 className="w-full text-gray-500 text-sm font-light text-center lg:text-left">{description}</h4>
          <h2 className="w-full text-gray-800 text-xl font-bold text-center lg:text-left">{title}</h2>
        </div>
      </div>
    </div>
  );
};

export default Framework;
