import { useEffect, useState } from 'react';
import { isDocumentReady, isWindowReady } from '@utils';

const calcPosition = (scroll, height) => (scroll / height) * 100 || 0;

const useScrollStatus = () => {
  let innerHeight;
  let scrollHeight;
  let scrollY;

  if (isDocumentReady && isWindowReady) {
    innerHeight = window.innerHeight;
    scrollHeight = document.documentElement.scrollHeight;
    scrollY = window.scrollY;
  }

  const [positionState, setPositionState] = useState(calcPosition(scrollY, scrollHeight - innerHeight));

  function handleScrollChange() {
    setPositionState(
      calcPosition(window.scrollY, document.documentElement.scrollHeight - window.innerHeight),
    );
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);

    return function cleanup() {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);

  return {
    position: positionState,
  };
};

export default useScrollStatus;
