import React from 'react';

import { ScrollTracker } from '@components';
import { ThemeContextProvider } from '@store';
import { useScrollStatus } from '@utils';
import Header from '@containers/header/header.container';
import Footer from '@containers/footer/footer.container';

const Layout = ({ children }) => {
  const scrollState = useScrollStatus();

  return (
    <ThemeContextProvider>
      <ScrollTracker percent={scrollState.position} />
      <Header position={'absolute'}></Header>
      <main className="w-full h-full bg-transparent dark:bg-charcoal">{children}</main>
      <Footer></Footer>
    </ThemeContextProvider>
  );
};

export default Layout;
