import React from 'react';

/**
 * @param {string} url
 * @param {*} children
 * @param {string} type link or button
 * @param {string} className
 */

const NavLink = (props) => {
  return (
    <a className={`component-navlink ${props.type || 'link'} ${props.className}`} href={props.url}>
      {props.children}
    </a>
  );
};

export default NavLink;
