import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Logo from '@static/svgs/logo/logo-with-text-grayscale.svg';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = { email: email };

    try {
      const response = await fetch('https://ovupdi9wvrn8.stackbase-api.com/signup/stackbase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setMessage('Subscribed!');
      } else {
        throw new Error('Failed to subscribe. Please try again later.');
      }
    } catch (error) {
      setMessage(error.message);
    }
  };


  return (
    <footer class="bg-gray-900 py-20">
      <div class="container mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex flex-wrap justify-between flex-gap-4 text-left">
          <div class="w-full sm:w-1/2 lg:w-1/4 mb-6 sm:mb-0">
            {/* <div className="w-40 h-10">
               <Logo />
           </div> */}
             <h3 class="w-full text-gray-800 text-m font-bold">Stackbase</h3>
            <p class="font-lato text-base text-dark-gray">Empowering Your Ideas, One Click at a Time.</p>

            <div class="flex gap-4 pt-3 justify-start">
              <a href="https://twitter.com/StackbaseHq" target="_blank" class="text-gray-500 hover:text-gray-700">
                <div className="flex w-6 h-6 text-light-gray">
                  <StaticImage className="rounded text-dark-gray" src="../../static/svgs/icons/twitter.svg" alt="API Payload"></StaticImage>
                </div>
              </a>
              <a href="https://www.instagram.com/stackbaseapp/" target="_blank" class="text-gray-500 hover:text-gray-700">
                <div className="flex w-6 h-6 text-light-gray">
                  <StaticImage className="rounded text-dark-gray" src="../../static/svgs/icons/facebook.svg" alt="API Payload"></StaticImage>
                </div>
              </a>
              <a href="https://www.facebook.com/stackbaseapp" target="_blank" class="font-lato text-base text-dark-gray">
                <div className="flex w-6 h-6 text-light-gray">
                  <StaticImage className="rounded text-dark-gray" src="../../static/svgs/icons/instagram.svg" alt="API Payload"></StaticImage>
                </div>
              </a>
            </div>
          </div>



          <div class="w-full sm:w-1/2 lg:w-1/4 mb-6 sm:mb-0">
            <h3 class="w-full text-gray-800 text-m font-bold">Links</h3>
            <ul class="list-none">
              <li><a href="https://docs.stackbase.io/" target="_blank" class="font-lato text-base text-dark-gray transition duration-300">Documentation</a></li>
              <li><a href="/blog" class="font-lato text-base text-dark-gray hover:text-primary transition duration-300">Blog</a></li>
              <li><a href="/agency" class="font-lato text-base text-dark-gray hover:text-primary transition duration-300">Agency</a></li>
            </ul>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/4 mb-6 sm:mb-0">
            <h3 class="w-full text-gray-800 text-m font-bold">Company</h3>
            <ul class="list-none">
              <li><a href="/contact" class="font-lato text-base text-dark-gray transition duration-300">Contact</a></li>
              <li><a href="/privacy" target="_blank" class="font-lato text-base text-dark-gray hover:text-primary transition duration-300">Privacy</a></li>
              <li><a href="/terms-of-use" target="_blank" class="font-lato text-base text-dark-gray hover:text-primary transition duration-300">Terms of use</a></li>
            </ul>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/4 mb-6 sm:mb-0 mx-auto">
            <h3 class="w-full text-gray-800 text-m font-bold">Subscribe</h3>
            <p class="font-lato text-base text-dark-gray mb-3">Subscribe to our newsletter for updates and special offers.</p>
            <div class="mx-auto">
              <form
                onSubmit={handleSubmit}
              >
                <div className="flex items-center">
                  <input
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    className="bg-gray-800 appearance-none border-2 border-gray-700 rounded py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:bg-gray-700"
                    placeholder="Enter your email address"
                  />
                  <button
                    type="submit"
                    className="ml-2 bg-primary hover:bg-blue-600 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue transition duration-300"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
              <div font-lato text-base text-dark-gray italic mt-3>{message}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6">
        <p class="font-lato text-base text-dark-gray text-center">&copy; 2023 Stackbase. All rights reserved.</p>
      </div>
    </footer>

  );
};

export default Footer;
